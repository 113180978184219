import React from 'react';
import PropTypes from 'prop-types';

const Button = (props) => {
  const { type, className, onClick, disabled, leftIcon, children, rightIcon, name } = props;
  return (
    <button type={type} className={className} onClick={onClick} disabled={disabled} name={name}>
      {leftIcon}
      {children}
      {rightIcon}
    </button>
  )
}

export default Button;

Button.propTypes = {
  type: PropTypes.any,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  leftIcon: PropTypes.any,
  children: PropTypes.any,
  rightIcon: PropTypes.any,
  name: PropTypes.any
}