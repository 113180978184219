import React from "react";

class ChunkErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    if (error && error.message && error.message.includes("Loading chunk")) {
      return { hasError: true };
    }
    return null;
  }

  componentDidCatch(error, info) {
    console.error("Chunk error detected::", error);
    window.location.reload();
  }

  componentDidUpdate() {
    if (this.state.hasError) {
      window.location.reload();
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="loader-block">
          <div className="loader" />
        </div>
      );
    }
    return this.props.children;
  }
}

export default ChunkErrorBoundary;
