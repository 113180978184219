import React from "react";

const Icon = (props) => {
  const { id, tooltip, style, iconName, iconSize, className, click } = props;

  return (
    <i
      id={id}
      title={tooltip}
      style={style}
      className={
        "icon icon-" +
        iconName +
        (iconSize ? " icon" + iconSize + " " : "") +
        (className ? " " + className : "")
      }
      onClick={click}
    />
  );
};

export default Icon;
