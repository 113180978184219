const excludeSupplierRoutes = (url) => {
  if (
    url === "company-details" ||
    url === "contact-details" ||
    url === "scope-of-supply" ||
    url === "certificates" ||
    url === "banking" ||
    url === "supplier-preview" ||
    url === "supplier-approval" ||
    url === "user-account" ||
    url === "user-feedback" ||
    url === "switch-user-tasks"
  ) {
    return true;
  } else {
    return false;
  }
};

const getURLFromURL = (url) => {
  if (url === "purchase-preview") {
    return "sourcing";
  } else if (url === "po-preview") {
    return "po-landing";
  } else if (url === "invoice-preview" || url === "non-po-invoice") {
    return "invoices";
  } else if (url === "preview-budget") {
    return "budgets";
  } else if (url === "viewMstLog") {
    return "manage-master-data";
  } else if (url === "interface-monitor-detail") {
    return "interface-monitor";
  } else if (url === "spend-details") {
    return "spend-analysis";
  } else if (url === "report-details") {
    return "myreports";
  } else if (url === "inventory-preview") {
    return "inventory";
  } else if (
    url === "bid-create" ||
    url === "bid-view" ||
    url === "bid-evaluate-response" ||
    url === "bid-response-linescore" ||
    url === "bid-tech-linescore" ||
    url === "bid-tech-score" ||
    url === "bid-comm-score" ||
    url === "bid-compare-responses" ||
    url === "bid-award-details"
  ) {
    return "bids";
  } else if (
    url === "bid-supplierView" ||
    url === "bid-response" ||
    url === "bid-response-view"
  ) {
    return "supplierBids";
  } else if (
    url === "contract-preview" ||
    url === "contractSections" ||
    url === "contractTerms"
  ) {
    return "contracts";
  } else if (url === "contract-supplierView") {
    return "supplierContracts";
  } else if (
    url === "create-item" ||
    url === "edit-item" ||
    url === "view-item"
  ) {
    return "manage-items";
  } else if (
    url === "auction-details" ||
    url === "award-auctions/auction-details" ||
    url === "compare-bids" ||
    url === "award-auctions" ||
    url === "auction-detailed-view" ||
    url === "auction-award-details" ||
    url === "auction-award-list"
  ) {
    return "manage-auctions";
  } else if (
    url === "supplierAuction-view" ||
    url === "supplierAuction-update-response" ||
    url === "supplierAuction-edit-response" ||
    url === "supplierAuction-response-view"
  ) {
    return "supplierAuctions";
  } else if (
    url === "checkout" ||
    url === "end-trail" ||
    url === "payment-response"
  ) {
    return "my-subscription";
  } else if (
    url === "account" ||
    url === "my-tasks" ||
    url === "notification-detail" ||
    url === "feedback"
  ) {
    return "dashboard";
  } else {
    return url;
  }
};

const setActiveMenu = (url) => {
  if (url === "supplier-approval") {
    return "supplier";
  } else if (url === "purchase-preview") {
    return "sourcing";
  } else if (url === "po-preview") {
    return "po-landing";
  } else if (url === "invoice-preview" || url === "non-po-invoice") {
    return "invoices";
  } else if (url === "preview-budget") {
    return "budgets";
  } else if (url === "viewMstLog") {
    return "manage-master-data";
  } else if (url === "interface-monitor-detail") {
    return "interface-monitor";
  } else if (url === "spend-details") {
    return "spend-analysis";
  } else if (url === "report-details") {
    return "myreports";
  } else if (url === "inventory-preview") {
    return "inventory";
  } else if (url === "view-item") {
    return "manage-items";
  } else if (
    url === "bid-create" ||
    url === "bid-view" ||
    url === "bid-evaluate-response" ||
    url === "bid-response-linescore" ||
    url === "bid-tech-linescore" ||
    url === "bid-tech-score" ||
    url === "bid-comm-score" ||
    url === "bid-compare-responses" ||
    url === "bid-award-details"
  ) {
    return "bids";
  } else if (
    url === "bid-supplierView" ||
    url === "bid-response" ||
    url === "bid-response-view"
  ) {
    return "supplierBids";
  } else if (
    url === "contract-preview" ||
    url === "contractSections" ||
    url === "contractTerms"
  ) {
    return "contracts";
  } else if (url === "contract-supplierView") {
    return "supplierContracts";
  } else if (
    url === "auction-details" ||
    url === "award-auctions/auction-details" ||
    url === "compare-bids" ||
    url === "award-auctions" ||
    url === "auction-detailed-view" ||
    url === "auction-award-details" ||
    url === "auction-award-list"
  ) {
    return "manage-auctions";
  } else if (
    url === "supplierAuction-view" ||
    url === "supplierAuction-update-response" ||
    url === "supplierAuction-edit-response" ||
    url === "supplierAuction-response-view"
  ) {
    return "supplierAuctions";
  } else if (
    url === "checkout" ||
    url === "end-trail" ||
    url === "payment-response"
  ) {
    return "my-subscription";
  }
};

export { excludeSupplierRoutes, getURLFromURL, setActiveMenu };
