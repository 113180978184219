import React, { useState, useEffect } from 'react';

const CircularBar = (props) => {
  let valuePercent = props.completed / props.total * 100;
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter => {
        if (valuePercent > counter) {
          return counter + 1;
        } else {
          return counter = valuePercent; 
        }
      });
    }, 40);

    return () => {
      clearInterval(interval);
    };
  }, [valuePercent]);

  return (
    <div className={"circular-bar " + (props.className ? props.className : '')}>
      <svg
        viewBox="0.5 0.5 35 35"
        className="circular-chart"
      >
        <path
          className="disabled-circle"
          strokeDasharray="100, 100"
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className="circle"
          strokeDasharray={valuePercent + ',100'}
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        />
      </svg>
      <div className="chart-label">
        <span className="value-number">{counter}{props.addition}</span>
      </div>
      {props.showlabel && <div className="value-label">{props.label}</div>}
    </div>
  )
}

const Progressbar = (props) => {
  return (
    <div className={"progress-bar-block " + (props.className ? props.className : '')}>
      <div className="progress-bar-block-data">
        <div className="bar-area" style={{ width: props.value + "%" }} />
      </div>
      {(props.showlabel || props.showvalue) && (
        <div className="d-flex justify-content-between">
          {props.showlabel && (<span className="label">{props.label}</span>)}
          {props.showvalue && (<span className="value">{props.value}%</span>)}
        </div>
      )}
    </div>
  )
}

export { CircularBar, Progressbar };
