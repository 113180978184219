import React, { Component } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Customtooltip from "../../components/customtooltip";
import Button from "../button";

class CustomModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      closeBtn: (
        <button className="close" onClick={this.toggleModal}>
          &times;
        </button>
      ),
    };
  }

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  render() {
    const state = this.state;
    const props = this.props;
    return (
      <Modal
        modalTransition={props.modalTransition && props.modalTransition}
        wrapClassName={
          "custom-modal " + (props.className ? props.className : "")
        }
        isOpen={state.showModal}
        toggle={this.toggleModal}
        backdrop={"static"}
        fade={!props.hideOverlay}
        size={props.size}
        centered={props.centered}
        zIndex={props.hideOverlay ? 10 : 1050}
        keyboard={props.keyboard}
      >
        {props.heading && (
          <ModalHeader
            toggle={!props.hideClose ? this.toggleModal : null}
            close={!props.hideClose ? state.closeBtn : null}
          >
            <div
              className={
                props.headingAdditions
                  ? "d-flex align-items-center justify-content-between w-100"
                  : "d-flex align-items-center"
              }
            >
              {props.heading}
              {props.headInfo && <Customtooltip item={props.headInfo} />}
              {props.headingAdditions && props.headingAdditions}
            </div>
            {props.subheading}
          </ModalHeader>
        )}
        <ModalBody className={props.bodyclass} style={props.bodyStyle}>
          {props.content}
        </ModalBody>
        {props.buttonName && (
          <ModalFooter className="col-12 text-center">
            <Button className="btn btn-primary" onClick={this.toggleModal}>
              {props.buttonName}
            </Button>
          </ModalFooter>
        )}
      </Modal>
    );
  }
}

export default CustomModal;
