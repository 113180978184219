import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import Icon from '../icon';

const Customtooltip = (props) => {
  const { item, click } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <React.Fragment>
      <Icon iconName={item.iconName} id={"Tooltip-" + item.name} click={click} className={item.iconClass} />
      <Tooltip
        popperClassName={props.class ? props.class : 'custom-tooltip'}
        placement={item.placement}
        isOpen={tooltipOpen}
        target={"Tooltip-" + item.name}
        toggle={toggle}
      >
        {item.tooltip}
      </Tooltip>
    </React.Fragment>
  );
};

export default Customtooltip;